import * as React from 'react';
import { PageProps } from 'gatsby';

import PresentationLayout from '~/components/layout/Presentation';

export default function typescriptAboutWorriesOfUsersWhoDoNotUseTypescript({ location: pLocation }: PageProps) {
  return (
    <PresentationLayout pathname={pLocation.pathname}>
      <div className="slides">
        <section>
          <div>
            <img
              src="/img/presentation/typescript/logo.png"
              alt="logo"
              style={{ width: '50%', margin: '0 auto', background: 'none', border: 'none' }}
            />
            <p style={{ margin: '20px 0 0 0' }}>기초부터 시작하는 타입스크립트 강의</p>
            <p style={{ margin: '20px 0 0 0' }}>타입스크립트란? - 타입스크립트를 사용하지 않는 유저들의 걱정</p>
          </div>
          <aside className="notes">
            여러가지 이유로 타입스크립트를 배우는걸 차일피일 미루고, 적용하는것에 부담을 갖는 경우가 많은데요. 어떤
            고민이 해결되지 않아 그렇게 미루는지 하나하나 알아보겠습니다.
          </aside>
        </section>

        <section>
          <div>
            <h2>1. 러닝커브가 크지 않나요?</h2>
            <div>
              <img
                src="/img/presentation/typescript/about/worries-of-users-who-do-not-use-typescript/running-curve-code.jpg"
                alt="example"
              />
            </div>
            <aside className="notes">
              <br />
              타입스크립트는 타입을 하나하나 적용해줄 수 있습니다. 아래 코드처럼 특정 함수에서는 타입을 지정하고, 특정
              함수에선 타입을 지정하지 않아도 괜찮습니다. 에디터에서도 타입이 지정된 부분에 대해서만 에러가 발생하게
              되는데 직접 적용한다면 예시 코드처럼 됩니다.
              <br />
              --예시코드--
              <br />
              에러는 타입이 지정된 코드와 연관된 부분에서만 발생하는 것을 볼 수 있습니다. 그렇기 때문에 기본적인
              부분부터 학습하며 프로젝트에 적용할 수 있습니다. 그날그날 배운 내용을 원하는 부분에 선택적으로 적용할 수
              있는데 러닝커브가 무작정 크다고 하긴 어렵지 않을까요? 예제코드처럼 특정 함수에는 타입 지정을, 특정
              함수에는 타입을 지정하지 않는 방식으로 개발을 진행하면 됩니다.
            </aside>
          </div>
        </section>

        <section>
          <div>
            <h2>2. 자바처럼 타입 체크를 하게되면 run-time 언어인 자바스크립트의 장점이 없어지지 않나요?</h2>
            <div>
              <img
                src="/img/presentation/typescript/about/worries-of-users-who-do-not-use-typescript/running-curve-code.jpg"
                alt="example"
              />
            </div>
          </div>
          <aside className="notes">
            타입스크립트를 사용한다는 것이 `동적 타입 언어`로써의 특징을 버린다는 것은 아닙니다. `동적 타입 언어`와
            `정적 타입 언어`의 장점을 모두 가져가겠다는 의미입니다. 자바에서는 타입을 지정하지 않는다면 에디터에서
            에러가 발생했겠지만, 타입스크립트에서는 tsconfig를 수정하여 특정 옵션을 바꿔준다면 에디터에서 문제가
            발생하지 않습니다. 선택적이라는 것이죠.
            <br />
            --예시코드--
            <br />
            앞에서 봤던 예시코드를 다시 보겠습니다. 에디터에서 에러가 발생한다고 알려주고 있지만, run을 하게되면
            타입스크립트가 자바스크립트로 빌드를 하는데, build가 된 이후에는 자바스크립트 코드가 되므로 에러가 발생하지
            않습니다.
          </aside>
        </section>

        <section>
          <h2>3. 업데이트는 잘 되고 있나요?</h2>
          <div>
            <img
              src="/img/presentation/typescript/about/worries-of-users-who-do-not-use-typescript/4-9-announcement.jpg"
              alt="example"
            />
          </div>

          <aside className="notes">
            타입스크립트 release note - https://github.com/microsoft/TypeScript/releases 를 보면 v4.9까지 나온 상태이며,
            마이너 버전 업데이트는 꾸준히 이뤄지고 있습니다. 업데이트는 매우 빈번히 진행되고 있습니다. 또한 업데이트에
            맞춰 문서도 잘 정리되어 있습니다.
            <br />
            이미지처럼 업데이트에 대한 문서도 잘 정리되어있습니다. 이에 관해서는 추후 강의에서 한번 알아보겠습니다.
          </aside>
        </section>

        <section>
          <h2>4. 자바스크립트로 이미 다 만들어 왔는데, 타입스크립트를 어떻게 적용하나요?</h2>
          <div style={{ width: '50%', margin: 'auto' }}>
            <img
              src="/img/presentation/typescript/about/worries-of-users-who-do-not-use-typescript/interface.jpg"
              alt="example"
            />
          </div>
          <aside className="notes">
            위에 말씀드렸듯이 typescript를 적용한다고 모든 부분에 타입스크립트를 한꺼번에 적용해야 하는건 아닙니다.
            에러가 발생한다면 tsconfig를 통해 에러를 해결할 수 있으며, 순차적으로 타입을 넣어주면 됩니다. 장점중 하나인
            동료와의 협업 시 안정성, 생산성 증가를 위해선 프로젝트에 typescript를 직접 적용해 줘야합니다. 하지만
            라이브러리를 만든다면 어떨까요? 타입스크립트 프로젝트에서 라이브러리 코드에 접근하려 하는데 라이브러리가
            자바스크립트라면 타입을 알 수 없기 때문에 문제가 됩니다.
            <br />
            프로젝트에서 해당 라이브러리의 타입만 추론할 수 있도록 도와주는 파일이 declaration 파일입니다. 그림처럼
            인터페이스와 같은 역할을 하게 되는 것이죠. **타입스크립트 적용 (.ts) or declaration 파일 (\*.d.ts) 생성**
            declaration 파일은 타입스크립트를 적용시키지 않은 코드의 타입을 추론할 수 있게 도와주는 방법입니다. 아래
            그림에서의 interface 역할을 합니다.
          </aside>
        </section>

        <section>
          <h2>5. 다른 타입 체킹 도구는 없나요?</h2>
          <ol>
            <li>JSDoc</li>
            <li>
              <a href="https://ko.reactjs.org/docs/static-type-checking.html" target="_blank">
                Flow
              </a>
            </li>
          </ol>
          <div>
            <img
              src="/img/presentation/typescript/about/worries-of-users-who-do-not-use-typescript/other-tools.jpg"
              alt="example"
            />
          </div>
          <aside className="notes">
            유사한 목표를 지향하는 도구로는 jsdoc이 있습니다. typescript처럼 정적으로 코드를 분석하고 결과를 주는 것보단
            이름 그대로 문서를 만들기 위한 용도입니다. 보통은 api겠죠? 하지만 명시적으로 타입을 기재해놓을 수 있고,
            팀원들과 협업 시 도움이 됩니다. 강제적이진 않기 때문에 항상 sync에 주의해야 합니다.
            <br />
            React를 사용한다면 [Flow](https://flow.org/) 를 사용할 수도 있습니다. 이는 React 문서에서 살펴볼 수
            있습니다.
            <br />
            추가적으로 flow는 annotation과 flow라는 워딩이 포함된 주석이 있어야지만 타입 검사를 진행함에 유의해야
            합니다.
          </aside>
        </section>

        <section>
          <h1>끝</h1>
          <div>감사합니다.</div>
        </section>
      </div>
    </PresentationLayout>
  );
}
